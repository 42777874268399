import { ViewBasic } from '@quatrecentquatre/manage-me';
import { ContactFormService } from '../services/contact-form';
// import { IMask } from 'imask/dist/imask';
import { bindAll } from 'underscore'

export class ContactForm extends ViewBasic{
    constructor(options) {
        super(options);

        this.selectSubject = this.el.querySelector('#select-subject');
        this.firstnameField = this.el.querySelector("#firstname");
        this.lastnameField = this.el.querySelector("#lastname");
        this.emailField = this.el.querySelector("#email");
        this.phoneField = this.el.querySelector("#phone");
        this.messageField = this.el.querySelector("#message");
        this.submitbBtn = this.el.querySelector("#submit_btn");
    }

    initialize() {
        bindAll(this, ['clickHandler','checkSubject','checkFirstname','checkLastname','checkEmail','checkPhone','checkMessage']);
        this.addEvents();

        // ContactFormService for fetch.
        this.service = new ContactFormService();

        let maskOptions = {
            mask: '(000)000-0000'
        };
        this.phoneMask = IMask(this.phoneField, maskOptions);

        if (window.location.hash.indexOf('#cartes-cadeaux') !== -1 || window.location.hash.indexOf('#gift-cards') !== -1) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    this.el.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top - 200,
            })
            this.selectSubject.value = 'gift-card';
            this.selectSubject.classList.add('has-value');
            Me.skin.getField(this.selectSubject).setSelection(3);
        }
    }

    addEvents(){
        this.submitbBtn.addEventListener('click', this.clickHandler);
        this.selectSubject.addEventListener('change', this.checkSubject);
        this.firstnameField.addEventListener('change', this.checkFirstname);
        this.lastnameField.addEventListener('change', this.checkLastname);
        this.emailField.addEventListener('change', this.checkEmail);
        this.phoneField.addEventListener('change', this.checkPhone);
        this.messageField.addEventListener('change', this.checkMessage);
    };

    removeEvents(){
        this.submitbBtn.removeEventListener('click', this.clickHandler);
        this.selectSubject.removeEventListener('change', this.checkSubject);
        this.firstnameField.removeEventListener('change', this.checkFirstname);
        this.lastnameField.removeEventListener('change', this.checkLastname);
        this.emailField.removeEventListener('change', this.checkEmail);
        this.phoneField.removeEventListener('change', this.checkPhone);
        this.messageField.removeEventListener('change', this.checkMessage);
    };

    /**
     * Press submit button
     * @param e
     */
    clickHandler(e){
        e.preventDefault();

        let scope = this;
        scope.el.className = "";
        scope.el.classList.add('loading');


        //validate the form
        if(this.verifyFields()){
            //send form if valid.

            grecaptcha.ready(function() {
                //execute recaptcha
                grecaptcha.execute(window.SETTINGS.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                    //save token for backend
                    scope.el.querySelector('[name="g-recaptcha-response"]').value = token;

                    //submit form with fetch api.
                    scope.service.sendContactMessage(scope.el.getAttribute('action'),new FormData(scope.el))
                        .then(response => {
                            if(response.errors){   
                                // form submit successfully, but errors returned
                                scope.el.querySelector('.error').innerHTML = response.errors[0];
                                scope.el.classList.add('error');
                            }else{
                                //success
                                scope.el.classList.add('success');
                                scope.manageSuccess();
                            }
                        })
                        .catch(error => {
                            //PHP error
                            scope.el.classList.add('error-php');
                        });
                });
            });
        }
    }

    /**
     * Shows the success message, clears all the fields and removes class "has-value" to all fields
     */
    manageSuccess(){
        this.el.reset();
        let filledInputs = this.el.querySelectorAll('.has-value');
        for (let i = 0; i <filledInputs.length ; i++) {
            filledInputs[i].classList.remove('has-value')
        }

        //Changes value of select field
        this.selectSubject.value = '' ;
        document.querySelector('.choice.hide').innerHTML = '';
        this.selectSubject.dispatchEvent(new Event('change'));
    }

    /**
     * Verifies all the fields
     * @returns {boolean}
     */
    verifyFields(){
        if (this.checkSubject(), this.checkFirstname(), this.checkLastname(), this.checkEmail(), this.checkPhone(), this.checkMessage()){
            return true;
        }
        this.el.classList.remove('loading');
        return false;
    }

    /**
     * Verifies subject select field
     * @returns {boolean}
     */
    checkSubject (){
        let valid = true;

        if(this.selectSubject.value === '' && !this.el.classList.contains('success')){
            this.selectSubject.classList.add('error');
            valid = false;
        }
        else{
            this.selectSubject.classList.remove('error');
        }
        return valid;
    }

    /**
     * Verifies firstname field
     * @returns {boolean}
     */
    checkFirstname(){
        let valid = true;

        if(this.firstnameField.value === ''){
            this.firstnameField.classList.add('error');
            valid = false;
        }
        else{
            this.firstnameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies lastname field
     * @returns {boolean}
     */
    checkLastname(){
        let valid = true;

        if(this.lastnameField.value === ''){
            this.lastnameField.classList.add('error');
            valid = false;
        }
        else{
            this.lastnameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies email field
     * @returns {boolean}
     */
    checkEmail(){
        let valid = true;
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(!this.emailField.value.match(emailRegex)){
            this.emailField.classList.add('error');
            valid = false;
        }
        else{
            this.emailField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies phone field
     * @returns {boolean}
     */
    checkPhone() {
        let valid = true;
        let phoneregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if(!this.phoneField.value.match(phoneregex) && this.phoneField.value != ''){
            this.phoneField.classList.add('error');
            valid = false;
        }
        else{
            this.phoneField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies message field
     * @returns {boolean}
     */
    checkMessage() {
        let valid = true;

        if(this.messageField.value === ''){
            this.messageField.classList.add('error');
            valid = false;
        }
        else{
            this.messageField.classList.remove('error');
        }

        return valid;
    }

    terminate(){
        this.removeEvents();
    };
}

Me.views['ContactForm'] = ContactForm;
