import { ViewBasic } from '@quatrecentquatre/manage-me';
import {bindAll} from "underscore";

export class DataLayerPush extends ViewBasic {
    constructor(options) {
        super(options);

        this.dataDetail = this.el.getAttribute('data-layer-details');

        if (!this.dataDetail) {
            return;
        }

        try {
            this.parsedDetail = JSON.parse(this.dataDetail);
        } catch (error) {
            console.error("Invalid JSON in data-layer-detail attribute:", error);
            return;
        }
    }

    initialize() {
        bindAll(this, ['pushDataLayer']);
        this.addEvents()
    }

    addEvents() {
        this.el.addEventListener('click', this.pushDataLayer);
    }

    removeEvents() {
        this.el.removeEventListener('click', this.pushDataLayer);
    }

    pushDataLayer(e) {
        window.dispatchEvent(new CustomEvent('dataLayer.push', {
            detail: this.parsedDetail
        }));
    }
}

Me.views['DataLayerPush'] = DataLayerPush;
