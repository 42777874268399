import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class GiftcardOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['refreshPage']);

        super.initialize();
    }

    addEvents() {
       super.addEvents()

        // Add event listener for the refresh button
        this.el.querySelector('.refresh-btn').addEventListener('click', this.refreshPage);
    }

    removeEvents() {
        super.removeEvents()
    }

    refreshPage() {
        window.location.reload();
    }

    // Override to prevent closing unless specified
    requestClose(e) {
        // Bypass close event
    }

    // Override to manage what happens when attempting to close
    close(e) {
        // Prevent default closing
    }
}

Me.views['GiftcardOverlay'] = GiftcardOverlay;
