import {Overlay} from './overlay';
import {bindAll} from 'underscore'

export class EventSportRestaurantOverlay extends Overlay {
    constructor(options) {
        super(options);

        this.block = document.querySelector('.pagebuilder-component-sports-schedule');
    }

    initialize() {
        bindAll(this, ['openOverlay']);
        super.initialize();
    }

    addEvents() {
        super.addEvents();

        if(this.block){
            this.block.querySelectorAll('.sport').forEach(event => {
                event.addEventListener('click', this.openOverlay)
            })
        }

    }

    openOverlay(e) {
        e.preventDefault();

        window.dispatchEvent(new CustomEvent('Overlay.open', {
            detail: {
                id: 'overlay-sport',
                event_id: e.currentTarget.getAttribute('data-event-id')
            }
        }));
    }

    removeEvents() {
        super.removeEvents();
        if(this.block) {
            this.block.querySelectorAll('.sport').forEach(event => {
                event.removeEventListener('click', this.openOverlay)
            })
        }
    }
}

Me.views['EventSportRestaurantOverlay'] = EventSportRestaurantOverlay;
