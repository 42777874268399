import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'
import gsap from "gsap";
import {EventRestaurantFinderService} from "../../services/event-restaurant-finder";

export class LoadEventRestaurants extends ViewBasic {
    constructor(options) {
        super(options);
        this.limitationsMessage = this.el.parentElement.querySelector('.limitations');
        this.loadMoreButton = this.el.querySelector('.load-more');
    }

    initialize() {
        bindAll(this, ['findRestaurants', 'hideRestaurants', 'loadMore']);
        this.service = new EventRestaurantFinderService();
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('Overlay.open', this.findRestaurants);
        window.addEventListener('Overlay.close', this.hideRestaurants);
        this.loadMoreButton.addEventListener('click', this.loadMore);
    }

    removeEvents() {
        window.removeEventListener('Overlay.close', this.findRestaurants);
        window.removeEventListener('Overlay.close', this.hideRestaurants);
        this.loadMoreButton.removeEventListener('click', this.loadMore);
    }

    findRestaurants(e) {
        const scope = this;
        scope.el.classList.add('loading');


        if(e.detail.id != scope.el.closest('section.overlay').id){
            return;
        }

        // scope.el.classList.add('loading');


        // Add the event ID to the hidden input directly in the form
        let hiddenInput = scope.el.querySelector('[name="fields[sport_event]"]');
        if (!hiddenInput) {
            hiddenInput = document.createElement('input');
            hiddenInput.type = 'hidden';
            hiddenInput.name = 'fields[sport_event]';
            scope.el.appendChild(hiddenInput);
        }
        hiddenInput.value = e.detail.event_id;
        const formData = new FormData(scope.el);

        scope.service.findRestaurant(scope.el.getAttribute('action'), formData)
            .then(response => {
                if (response.success) {

                    const {eventName, sportTitle, eventDate} = response.eventDetails;
                    const formattedDate = this.formatEventDate(eventDate);

                    // Update the overlay with formatted date and other details
                    scope.el.parentElement.querySelector('.event-title').textContent = eventName;
                    scope.el.parentElement.querySelector('.sport-title').textContent = sportTitle;
                    scope.el.parentElement.querySelector('.date').textContent = formattedDate;


                    gsap.to(scope.el.parentElement.querySelector('.sport-event-details'),
                        {
                            opacity: 0, duration: 0.66,
                            onComplete: function () {

                                // Using requestAnimationFrame for batch rendering updates
                                requestAnimationFrame(() => {
                                    scope.el.parentElement.querySelector('.sport-event-details').classList.remove('hide');
                                });

                                gsap.to(scope.el.parentElement.querySelector('.sport-event-details'), {
                                    opacity: 1,
                                    duration: 0.66,
                                    delay: 0
                                });
                            }
                        }
                    );

                    if (response.restaurants.length > 0) {
                        const restaurantIds = new Set(response.restaurants.map(restaurant => `restaurant-${restaurant.id}`));
                        const restaurants = Array.from(scope.el.querySelectorAll('.sport-event'));

                        // Hide all restaurants initially
                        restaurants.forEach(restaurant => restaurant.classList.add('hide'));

                        gsap.to(scope.el.querySelector('.sports-list'),

                            {
                                opacity: 0, duration: 0.66,
                                onComplete: function () {

                                    let shownCount = 0;
                                    // Using requestAnimationFrame for batch rendering updates
                                    requestAnimationFrame(() => {
                                        // Show only the relevant restaurants
                                        restaurants.forEach(restaurantElement => {
                                            if (restaurantIds.has(restaurantElement.id)) {
                                                restaurantElement.classList.add('related-to-event');

                                                if (shownCount < 6) {
                                                    restaurantElement.classList.remove('hide');
                                                    shownCount++;
                                                }
                                            }
                                        });

                                        scope.el.classList.remove('loading');

                                        const hiddenRestaurants = scope.el.querySelectorAll('.sport-event.related-to-event.hide');

                                        if (hiddenRestaurants.length > 0) {
                                            scope.loadMoreButton.classList.remove('hide');
                                        } else {
                                            scope.loadMoreButton.classList.add('hide');
                                        }


                                        if (scope.limitationsMessage) {
                                            if (restaurants.some(restaurant => !restaurant.classList.contains('related-to-event'))) {
                                                scope.limitationsMessage.classList.remove('hide');
                                            } else{
                                                scope.limitationsMessage.classList.add('hide');
                                            }
                                        }
                                    });

                                    gsap.to(scope.el.querySelector('.sports-list'), {
                                        opacity: 1,
                                        duration: 0.66,
                                        delay: 0.25
                                    });


                                }
                            }
                        );
                    }


                } else {
                    console.error('No restaurants found for this event.');
                    scope.el.classList.add('error-php');
                    scope.el.classList.remove('loading');

                }
            })
            .catch(error => {
                console.error('Error fetching restaurants:', error);
                scope.el.classList.remove('loading');
                scope.el.classList.add('error-php');
            });
    }


    formatEventDate(eventDateStr) {
        const eventDate = new Date(eventDateStr);
        const lang = document.documentElement.lang || 'en';

        const dateOptions = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
        };

        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat(lang, dateOptions).format(eventDate);
        const formattedTime = new Intl.DateTimeFormat(lang, timeOptions).format(eventDate);

        // Capitalize the first letter of the formatted date
        const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        // Only add a comma after the day name if it doesn't already exist
        const hasComma = capitalizedDate.includes(',');  // Check if a comma is already present
        const dateWithComma = hasComma
            ? capitalizedDate  // Use the date as-is if it already has a comma
            : capitalizedDate.replace(/^\S+\s/, (match) => `${match.trim()}, `);  // Insert a comma after the first word

        return `${dateWithComma} @ ${formattedTime}`;
    }


    hideRestaurants(){
        const detailsElement = this.el.parentElement.querySelector('.sport-event-details');
        if (detailsElement) {
            detailsElement.classList.add('hide');
        }

        this.limitationsMessage.classList.add('hide')
        this.loadMoreButton.classList.add('hide');
        this.el.classList.remove('loading');
        this.el.classList.remove('error');
        this.el.classList.remove('error-php');


        this.el.querySelectorAll('.sport-event.related-to-event').forEach(restaurantElement => {
            restaurantElement.classList.add('hide');
            restaurantElement.classList.remove('related-to-event');
        });
    }

    loadMore(e) {
        e.preventDefault();
        let index = 0;

        // Show next 6 hidden restaurants related to the event
        this.el.querySelectorAll('.sport-event.related-to-event.hide').forEach(element => {
            if (index < 6) {
                index++;
                element.classList.remove('hide');
            }
        });

        // Hide the Load More button if no more related hidden restaurants
        if (this.el.querySelectorAll('.sport-event.related-to-event.hide').length === 0) {
            this.loadMoreButton.classList.add('hide');
        }

    }



}

Me.views['LoadEventRestaurants'] = LoadEventRestaurants;
