import { ViewBasic } from '@quatrecentquatre/manage-me';
import { CheckBalanceFormService } from '../../services/check-balance-form';
import { bindAll } from 'underscore'


export class CheckBalanceForm extends ViewBasic{

    constructor(options) {
        super(options);

        this.cardNumber = this.el.querySelector("#card-number");
        this.submitBtn = this.el.querySelector("#submit_btn");
    }

    initialize() {

        bindAll(this, ['checkNumber', 'clickHandler']);
        this.addEvents();

        // FormService for fetch.
        this.service = new CheckBalanceFormService();

    }

    addEvents(){
        let scope = this;

        this.cardNumber.addEventListener('change', this.checkNumber);
        this.submitBtn.addEventListener('click', this.clickHandler);
    };

    removeEvents(){
        this.submitBtn.removeEventListener('click', this.clickHandler);
        this.cardNumber.removeEventListener('change', this.checkNumber);
    };

    /**
     * Press submit button
     * @param e
     */
    clickHandler(e){
        e.preventDefault();

        let scope = this;
        scope.el.className = "";
        scope.el.classList.add('loading');

        //validate the form
        if(this.verifyFields()){
            //send form if valid.

            grecaptcha.ready(function() {
                //execute recaptcha
                grecaptcha.execute(window.SETTINGS.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                    //save token for backend
                    scope.el.querySelector('[name="g-recaptcha-response"]').value = token;

                    //submit form with fetch api.
                    scope.service.checkBalanceForm(scope.el.getAttribute('action'),new FormData(scope.el))
                        .then(response => {
                            if(response.errors){
                                // form submit successfully, but errors returned
                                scope.el.querySelector('.error').innerHTML = response.errors[0];
                                scope.el.classList.add('error');
                            }else{
                                scope.el.classList.remove('loading');
                                scope.el.querySelector('.amount').classList.remove('hide');
                                scope.el.querySelector('.amount .value').innerHTML = response.amount;
                                //window.location = response.redirectUrl;
                            }
                        })
                        .catch(error => {
                            //PHP error
                            scope.el.classList.add('error-php');
                        });
                });
            });
        }else{
            window.scrollTo({
                behavior: 'smooth',
                top:
                    this.el.querySelectorAll('.error')[0].getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top - 200,
            })
        }
    }

    /**
     * Verifies all the fields
     * @returns {boolean}
     */
    verifyFields(){
        if (this.checkNumber()){
            return true;
        }

        this.el.classList.remove('loading');
        return false;
    }

    checkNumber(){
        let valid = true;

        if(this.cardNumber.value === ''){
            this.cardNumber.classList.add('error');
            valid = false;
        }
        else{
            this.cardNumber.classList.remove('error');
        }

        return valid;
    }

    terminate(){
        this.removeEvents();
    };
}

Me.views['CheckBalanceForm'] = CheckBalanceForm;
