import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'
import {CardFormService} from "../../services/card-form";
import barba from '@barba/core';

export class CartForm extends ViewBasic{

    constructor(options) {
        super(options);

        this.timeoutClickDisabled = false;


        this.emailField = this.el.querySelector("#sender_email");
        this.nameField = this.el.querySelector("#sender_name");
        this.addressField = this.el.querySelector("#sender_address");
        this.cityField = this.el.querySelector("#sender_city");
        this.provinceField = this.el.querySelector("#sender_province");
        this.zipcodeField = this.el.querySelector("#sender_zipcode");
        this.conditionField = this.el.querySelector("#sender_conditions");


        this.cardLabel = this.el.querySelector('#card-number label')
        this.cardCvv = this.el.querySelector('#card-cvv label')
        this.cardExpiration = this.el.querySelector('#card-expiration label')
        this.cardName = this.el.querySelector('#card-holder-name label')
        this.cvvExpError = this.el.querySelector('#cvv-expiry-error')

        this.submitBtn = this.el.querySelector("#submit-button");

        this.accessToken = this.el.querySelector('input[name="access-token"]').value
        this.env = this.el.querySelector('input[name="env"]').value

        // FormService for fetch.
        this.service = new CardFormService();

        // Data Layer

        this.modifyLink = this.el.parentElement.querySelector('aside .buttons a:first-of-type');
        this.deleteLink = this.el.parentElement.querySelector('aside .buttons a:last-of-type');
        this.addNewCardLink = this.el.parentElement.querySelector('aside .total-wrapper a');

        // Initialize Global Payment Configs
        GlobalPayments.configure({
            accessToken: this.accessToken,
            apiVersion: "2021-03-22",
            env: this.env,
            requireCardHolderName: true,
            enableAutocomplete: true,
            enableTwoDigitExpirationYear: true,
            fieldValidation: {
                enabled: true
            }
        });

    }

    initialize() {
        bindAll(this, [ 'clickHandler','checkName','checkEmail','checkAddress','checkCity','checkProvince','checkZipcode', 'checkCondition']);
        this.initGlobalPayment();
        this.addEvents();
        this.addDataLayerEvents();

        this.el.querySelectorAll('input').forEach(function(element){
            if (element.value) {
                element.classList.add('has-value');
            }
        });
    }

    addEvents(){
        this.nameField.addEventListener('change', this.checkName);
        this.emailField.addEventListener('change', this.checkEmail);
        this.addressField.addEventListener('change', this.checkAddress);
        this.cityField.addEventListener('change', this.checkCity);
        this.provinceField.addEventListener('change', this.checkProvince);
        this.zipcodeField.addEventListener('change', this.checkZipcode);
        this.conditionField.addEventListener('change', this.checkCondition);

        // Global Payment Events

        this.cardForm.on("token-success", (resp) => {
            this.paymentToken = resp.paymentReference; // Capture token
        });

        this.cardForm.on("token-error", (resp) => {
            this.handleTokenError(resp);
        });

        // The on event captures input and returns a response every time a user types in the field.
        // Add debounce function to check the validity of the field after 10 seconds.

        this.cardForm.on("card-number-test", (resp) => {
            this.cardLabel.classList.add('active');

            if (resp.valid) {
                clearTimeout(this.cvvTimer);
                this.updateFieldValidity("#card-number", true);
            } else {
                clearTimeout(this.cvvTimer);
                this.cvvTimer = setTimeout(() => {
                    this.updateFieldValidity("#card-number", false);
                }, 3000);
            }
        });

        this.cardForm.on("card-expiration-test", (resp) => {
            this.cardExpiration.classList.add('active');
            if(this.cvvExpError.classList.contains('error')){
                this.cvvExpError.classList.remove('error')
            }

            if (resp.valid) {
                clearTimeout(this.cvvTimer);
                this.updateFieldValidity("#card-expiration", true);
            } else {
                clearTimeout(this.cvvTimer);
                this.cvvTimer = setTimeout(() => {
                    this.updateFieldValidity("#card-expiration", false);
                }, 3000);
            }
        });

        this.cardForm.on("card-type", (resp) =>{
            this.cardNumber = this.el.querySelector('#card-number')

           switch(resp.cardType){
                case "visa":
                    this.cardNumber.classList.add('visa');
                    this.cardNumber.classList.remove('mastercard');
                    break;
                case "mastercard":
                    this.cardNumber.classList.add('mastercard');
                    this.cardNumber.classList.remove('visa');

                    break;
               default:
                   console.error('Unrecognized card:', resp);
                   return;
            }
        });

        this.cardForm.on("card-cvv-test", (resp) => {
            this.cardCvv.classList.add('active');
            if(this.cvvExpError.classList.contains('error')){
                this.cvvExpError.classList.remove('error')
            }
            if (resp.valid) {
                clearTimeout(this.cvvTimer);
                this.updateFieldValidity("#card-cvv", true);
            } else {
                clearTimeout(this.cvvTimer);
                this.cvvTimer = setTimeout(() => {
                    this.updateFieldValidity("#card-cvv", false);
                }, 3000);
            }
        });

        this.cardForm.on("submit", "click", (resp) => {
            this.clickHandler({
                preventDefault: () => {},
                target: this.submitBtn
            });
        });


    };

    removeEvents(){

        this.nameField.removeEventListener('change', this.checkName);
        this.emailField.removeEventListener('change', this.checkEmail);
        this.addressField.removeEventListener('change', this.checkAddress);
        this.cityField.removeEventListener('change', this.checkCity);
        this.provinceField.removeEventListener('change', this.checkProvince);
        this.zipcodeField.removeEventListener('change', this.checkZipcode);
        this.conditionField.removeEventListener('change', this.checkCondition);


    };


    /**
     * Init Global Payment fields and style.
     */
    initGlobalPayment(){
        // For the submit button text
        const lang = document.documentElement.lang;
        const contentText = lang === "fr" ? "'Payer maintenant'" : "'Pay now'";


        // Parse translated errors
        const cardErrors = JSON.parse(this.el.querySelector('#card-number .error-msg').getAttribute('data-card-errors'));
        const cvvErrors = JSON.parse(this.el.querySelector('#card-cvv .error-msg').getAttribute('data-cvv-errors'));
        const expiryErrors = JSON.parse(this.el.querySelector('#card-expiration .error-msg').getAttribute('data-expiry-errors'));
        const nameErrors = JSON.parse(this.el.querySelector('#card-holder-name .error-msg').getAttribute('data-name-errors'));


        this.cardForm = GlobalPayments.ui.form({
            fields: {
                "card-holder-name": {
                    target: "#card-holder-name",
                    placeholder: ".",
                    label: this.el.querySelector('#card-holder-name').getAttribute('data-label'),
                    validationMessages: {
                        NotValidCardHolderName: nameErrors.NotValidCardHolderName,
                        CharactersMoreThan100: nameErrors.CharactersMoreThan100
                    }
                },
                "card-number": {
                    target: "#card-number",
                    placeholder: "",
                    validationMessages: {
                        Required: cardErrors.Required,
                        CharactersLessThan12: cardErrors.CharactersLessThan12,
                        NumberIsNotValid: cardErrors.NumberIsNotValid,
                        NotAllowedCardType: cardErrors.NotAllowedCardType
                    },
                    label: this.el.querySelector('#card-number').getAttribute('data-label'),
                },
                "card-expiration": {
                    target: "#card-expiration",
                    placeholder: ".",
                    label: this.el.querySelector('#card-expiration').getAttribute('data-label'),
                    validationMessages: {
                        NotCompleted: expiryErrors.NotCompleted,
                        YearNotValid: expiryErrors.YearNotValid,
                        MonthNotValid: expiryErrors.MonthNotValid,
                        ExpiryDateNotValid: expiryErrors.ExpiryDateNotValid
                    }
                },
                "card-cvv": {
                    target: "#card-cvv",
                    placeholder: ".",
                    label: this.el.querySelector('#card-cvv').getAttribute('data-label'),
                    validationMessages: {
                        CodeIsNotValid: cvvErrors.CodeIsNotValid,
                        CodeIsLessThan3Digits: cvvErrors.CodeIsLessThan3Digits,
                        CodeMustBe3Digits: cvvErrors.CodeMustBe3Digits,
                        AmexCodeMustBe4Digits: 'Card CVV for Amex must be 4 digits',
                    }
                },
                "submit": { target: "#submit-button" }
            },
            styles: {
                "@font-face": [{
                    "font-family": "'Shentox-Bold'",
                    "src": "url('https://assets.cage.ca/fonts/shentox-bold.woff2') format('woff2')",
                    "font-display": "swap"
                },
                {
                    "font-family": "'Ciutadella-SemiBold'",
                    "src": "url('https://assets.cage.ca/fonts/Ciutadella-SemiBold.otf') format('opentype')",
                    "font-display": "swap"
                },
                    {
                    "font-family": "'Ciutadella-Regular'",
                    "src": "url('https://assets.cage.ca/fonts/Ciutadella-Regular.otf') format('opentype')",
                    "font-display": "swap"
                }
                ],

                // Base button styles
                "button[type=button]": {
                    "outline": "none",
                    "margin": "0",
                    "cursor": "pointer",
                    "text-decoration": "none",
                    "display": "inline-flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "font-size": "0px",
                    "line-height": "14px",
                    "letter-spacing": "0.05em",
                    "text-transform": "uppercase",
                    "border-radius": "100px",
                    "border": "1px solid transparent",
                    "color": "#FFF",
                    "background-color": "#da291c",
                    "transition": "color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out",
                    "font-weight": "normal",
                    "font-family": "'Shentox-Bold', Arial, helvetica, sans-serif",
                    "position": "relative",
                    "overflow": "hidden",
                    "flex": "unset !important",
                    "width": "unset !important",
                },
                "button[type=button]::before": {
                    "font-family": "'Shentox-Bold', Arial, helvetica, sans-serif",
                    "content": contentText,
                    "position": "relative",
                    "font-size": "14px",
                    "background-color": "#da291c",
                    "line-height": "14px",
                    "letter-spacing": "0.05em",
                    "padding": "14px 19px",
                    "white-space": "nowrap",
                    "transition": "color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out",
                    "pointer-events": "none",
                    "-webkit-font-smoothing": "antialiased"
                },
                "button[type=button]:disabled": {
                    "pointer-events": "none"
                },
                "button[type=button]:hover": {
                    "background-color": "#9d1d06"
                },
                "button[type=button]:hover::before": {
                    "background-color": "#9d1d06"
                },
                // Active state
                "button[type=button]:active": {
                    "background-color": "#21262a",
                    "border": "1px solid #FFF"
                },
                "button[type=button]:active::before": {
                    "background-color": "#21262a"
                },
                "input": {
                    "border": "0 solid #6a717e",
                    "border-bottom-width": "1px",
                    "background": "transparent",
                    "transition": "border 200ms ease-in-out",
                    "width": "100%",
                    "font-weight": "500",
                    "font-family": "'Ciutadella-SemiBold', Arial, helvetica, sans-serif",
                    "font-size": "17px",
                    "line-height": "1.7rem",
                    "color": "#21262a",
                    "box-sizing": "border-box",
                    "padding": "0",
                    "position": "relative",
                    "border-radius": "unset",
                    "top": "7px",
                    "-webkit-font-smoothing": "antialiased"
                },
                "input:hover, input:focus, input.possibly-valid": {
                    "border-color": "#000000",
                    "outline": "none",
                    "color": "currentColor"
                },
                "input.invalid": {
                    "border-color": "#FF0000"
                },
                "#secure-payment-field": {
                    "flex" : "unset"
                 },
                ".offscreen": {
                    "top" : "unset",
                    "height" : "unset",
                    "width" : "unset",
                },
                "label": {
                    "font-size": "17px",
                    "line-height": "1.7rem",
                    "color": "#6a717e",
                    "top": "calc(50% - 6px)",
                    "pointer-events": "none",
                    "width": "100%",
                    "position": "relative",
                    "font-family": "'Ciutadella-Regular', Arial, helvetica, sans-serif",
                    "-webkit-font-smoothing": "antialiased",
                    "transition" : "all 200ms ease-in-out",
                    "padding-left": "1px"

                },
                "label::after": {
                    "content": "'*'",
                    "color": "red",
                    "position": "absolute",
                    "right": "-10px"
                },
                "input:placeholder-shown + label": {
                    "top": "calc(50% - 6px)"
                },
                "#secure-payment-field-wrapper:has(input.card-expiration:focus) label, #secure-payment-field-wrapper:has(input.card-holder-name:focus) label, #secure-payment-field-wrapper:has(input.card-cvv:focus) label, #secure-payment-field-wrapper:has(input.card-expiration:not(:placeholder-shown)) label, #secure-payment-field-wrapper:has(input.card-holder-name:not(:placeholder-shown)) label, #secure-payment-field-wrapper:has(input.card-cvv:not(:placeholder-shown)) label": {
                    "top": "-12px",
                    "font-size": "14px",
                    "line-height": "16.8px",
                    "padding": "0"
                },
                "#secure-payment-field-wrapper:has(input.card-number.valid) label, #secure-payment-field-wrapper:has(input.card-number.invalid) label, #secure-payment-field-wrapper:has(input.card-number:focus) label": {
                    "top": "-12px",
                    "font-size": "14px",
                    "line-height": "16.8px",
                    "padding": "0"
                },
                "input::placeholder": {
                    "color": "transparent"
                },
                ".error .error-msg": {
                    "display": "block"
                },
                "#secure-payment-field-wrapper" :{
                    "position": "relative",
                    "margin-top": "12px"
                },
                "#field-validation-message": {
                    "margin-top": "5px",
                    "position": "absolute",
                    "font-family": "'Ciutadella-Regular', Arial, helvetica, sans-serif",
                    "-webkit-font-smoothing": "antialiased"
                },
            }
        });
    }

    /**
     * Adds error to field based on response returned by Global Payment. Listens to an input event.
     * @param selector
     * @param isValid
     */

    updateFieldValidity(selector, isValid) {
        const fieldElement= this.el.querySelector(selector);
        if (isValid) {
            fieldElement.classList.remove('error');
        } else {
            fieldElement.classList.add('error');
        }
    }

    handleTokenSuccess(paymentReference) {
        this.updatePaymentToken(paymentReference);
        this.ajaxSubmit();
    }

    handleTokenError(resp) {
        this.updatePaymentToken(null);

        if (resp.reasons) {
            resp.reasons.forEach(reason => {
                this.addErrorToField(reason.code);
                this.paymentToken = null;
                setTimeout(() => {
                    this.submitBtn.classList.remove('disabled');
                    this.el.classList.remove('loading');
                }, 2000);

            });
        }
    }

    updatePaymentToken(token) {
        let tokenInput = this.el.querySelector('input[name="payment-reference"]');
        if (token) {
            if (!tokenInput) {
                tokenInput = document.createElement("input");
                tokenInput.type = "hidden";
                tokenInput.name = "payment-reference";
                this.el.appendChild(tokenInput);
            }
            tokenInput.value = token;
        } else {
            // Remove the token input if the token is null or needs to be cleared
            if (tokenInput) {
                tokenInput.parentNode.removeChild(tokenInput);
            }
        }
    }

    ajaxSubmit() {
        const formData = new FormData(this.el);

        //submit form with fetch api.
        this.service.sendCardForm(this.el.getAttribute('action'), formData)
            .then(response => {
                if(response.errors){
                    // form submit successfully, but errors returned
                    this.el.querySelector('.error-message').innerHTML = response.errors[0];
                    this.el.classList.add('error');
                } else{
                    //success

                    // Push to dataLayer
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'pay-gift-cart',
                        'amount': formData.get('total-amount'),
                        'carts': formData.get('card-amount')
                    });

                    barba.go(response.redirectUrl)
                }
            })
            .catch(error => {
                //PHP error
                this.el.classList.add('error-php');
            })
            .finally(() => {
                setTimeout(() => {
                    this.submitBtn.classList.remove('disabled');
                }, 2000);
            });
    }


    /**
     * This function adds an error class to Global Payment fields based on response from token
     * @param errorCode
     */
    addErrorToField(errorCode) {

        let fieldSelector;
        switch (errorCode) {
            case "INVALID_CARD_NUMBER":
                fieldSelector = "#card-number";
                break;
            case "MANDATORY_DATA_MISSING":
                fieldSelector = "#cvv-expiry-error";
                break;
            case "INVALID_CARD_HOLDER_NAME":
                fieldSelector = "#card-holder-name";
                break;
            case "NOT_AUTHENTICATED":
                window.dispatchEvent(new CustomEvent('Overlay.open',
                    {
                        detail: {
                            id: 'overlay-giftcard'
                        }
                    }
                ));
                break;
            default:
                console.error('Unrecognized error code:', errorCode);
                return;
        }

        const fieldElement = this.el.querySelector(fieldSelector);
        if (fieldElement) {
            fieldElement.classList.add('error');
        }
    }
    /**
     * Press submit button
     * @param e
     */

    clickHandler(e) {
        e.preventDefault();

        let scope = this;
        scope.el.className = "light-form";
        scope.el.classList.add('loading');
        scope.submitBtn.classList.add('disabled');
        this.paymentToken = null;

        if (this.verifyFields()) {
            grecaptcha.ready(() => {
                grecaptcha.execute(window.SETTINGS.RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
                    scope.el.querySelector('[name="g-recaptcha-response"]').value = token;

                    // Retry mechanism to wait for the payment token
                    const retryToken = (attempts) => {
                        if (this.paymentToken) {
                            this.handleTokenSuccess(this.paymentToken);
                        } else if (attempts > 0) {
                            setTimeout(() => {
                                retryToken(attempts - 1);
                            }, 1000);
                        }
                    };

                    retryToken(5);
                });
            });
        } else {
            this.el.classList.remove('loading');
            window.scrollTo({
                behavior: 'smooth',
                top: this.el.querySelectorAll('.error')[0].getBoundingClientRect().top - document.body.getBoundingClientRect().top - 200,
            });
            setTimeout(()=> {
                scope.submitBtn.classList.remove('disabled');
            }, 2000)
        }
    }

    /**
     * Add Data Layer Tracking Events
     */

    addDataLayerEvents() {

        if (this.modifyLink) {
            this.modifyLink.addEventListener('click', () => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 'event': 'modify-gift-cart' });
            });
        }

        if (this.deleteLink) {
            this.deleteLink.addEventListener('click', () => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 'event': 'delete-gift-cart' });
            });
        }

        if (this.addNewCardLink) {
            this.addNewCardLink.addEventListener('click', () => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 'event': 'add-new-gift-cart' });
            });
        }
    }

    /**
     * Verifies all the fields
     * @returns {boolean}
     */
    verifyFields() {
        let isValid = true;

        const fields = [
            this.checkEmail,
            this.checkName,
            this.checkAddress,
            this.checkCity,
            this.checkProvince,
            this.checkZipcode,
            this.checkCondition
        ];

        fields.forEach(field => {
            if (!field()) {
                isValid = false;
            }
        });

        if (!isValid) {
            this.el.classList.remove('loading');
        }

        return isValid;
    }



    /**
     * Verifies name field
     * @returns {boolean}
     */
    checkName(){
        let valid = true;

        if(this.nameField.value === ''){
            this.nameField.classList.add('error');
            valid = false;
        }
        else{
            this.nameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies address field
     * @returns {boolean}
     */
    checkAddress(){
        let valid = true;

        if(this.addressField.value === ''){
            this.addressField.classList.add('error');
            valid = false;
        }
        else{
            this.addressField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies city field
     * @returns {boolean}
     */
    checkCity(){
        let valid = true;

        if(this.cityField.value === ''){
            this.cityField.classList.add('error');
            valid = false;
        }
        else{
            this.cityField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies province field
     * @returns {boolean}
     */
    checkProvince(){
        let valid = true;

        if(this.provinceField.value === ''){
            this.provinceField.classList.add('error');
            valid = false;
        }
        else{
            this.provinceField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies email field
     * @returns {boolean}
     */
    checkEmail(){
        let valid = true;
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(!this.emailField.value.match(emailRegex)){
            this.emailField.classList.add('error');
            valid = false;
        }
        else{
            this.emailField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies zipcode field
     * @returns {boolean}
     */
    checkZipcode(){
        let valid = true;
        let zipcodeRegex = /^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/i;

        if(!this.zipcodeField.value.match(zipcodeRegex)){
            this.zipcodeField.classList.add('error');
            valid = false;
        }
        else{
            this.zipcodeField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies conditions field
     * @returns {boolean}
     */
    checkCondition(){
        let valid = true;

        if(!this.conditionField.checked){
            this.conditionField.classList.add('error');
            valid = false;
        }
        else{
            this.conditionField.classList.remove('error');
        }

        return valid;
    }

    terminate(){
        this.removeEvents();
    };

}

Me.views['CartForm'] = CartForm;
